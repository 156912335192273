<script setup lang="ts">
const { t } = useT();
const { open } = useFunrizeModals();
const loginGuard = useLoginGuard();
const { isFlipTheCoinAvailable, typeValue, refreshBonusesData, durationLeft, reset } = useFlipTheCoin();
const handleFlipTheCoinClick = () => {
	loginGuard({
		success: () => {
			open("LazyOModalFlipTheCoin");
			refreshBonusesData();
		},
		fail: () => {
			open("LazyOModalLogin");
		}
	});
};

watch(
	() => typeValue.value,
	(value) => {
		if (value) {
			reset(value);
		}
	}
);
</script>

<template>
	<div class="wrapper" @click="handleFlipTheCoinClick">
		<AText v-if="isFlipTheCoinAvailable" :modifiers="['bold']" class="text-coimbatore">
			{{ t("Flip Lucky Coin") }}
		</AText>
		<AText v-else class="text-cannes">
			<span class="text-conakry">{{ t("Next in") }}</span> <br />
			<b>{{ durationLeft }}</b>
		</AText>

		<NuxtImg
			src="/nuxt-img/flipCoin/flip-the-coin.png"
			alt="funrize flip the coin"
			format="webp"
			width="79"
			height="56"
		/>

		<ABadge v-if="isFlipTheCoinAvailable">
			<AText variant="texas">1</AText>
		</ABadge>
	</div>
</template>

<style lang="scss" scoped>
.wrapper {
	width: 100%;
	height: 56px;
	background: var(--goma);
	border-radius: gutter(1);
	padding-left: gutter(1);
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;

	&:hover {
		cursor: pointer;
	}
}

.badge {
	position: absolute;
	top: -6px;
	right: 0;
	z-index: 1;
}
</style>
